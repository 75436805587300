.intro__heading strong {
    color: #2980b9;
}

.intro__links {
    list-style-type: none;
    margin: 30px 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

.intro__links li {
    margin: 0 10px;
}

.intro__button {
    display: block;
    padding: 10px 20px;
    border: 2px solid #2980b9;
    min-width: 120px;
    text-decoration: none;
    color: #2980b9;
    transition: 0.3s;
    font-size: 18px;
}

.intro__button:hover {
    background: #2980b9;
    color: #fff
}