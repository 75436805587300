.app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;
  height: 100%;
  text-align: center;
}

.app__header {
  padding: 20px;
  text-align: left;
}

.app__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app__footer {
  padding: 20px;
}
