html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Catamaran', sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #2a2a2a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(240,240,240,1) 83%, rgba(228,228,228,1) 100%);
}

h1, p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1 {
  font-size: 24px;
  line-height: 30px;
}

p {
  font-size: 18px;
  line-height: 22px;
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 42px;
    line-height: 48px;
  }

  p {
    font-size: 30px;
    line-height: 38px;
  }
}
